/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:49:09 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-07 11:33:21
 */
<template>
  <div class="coupon">
    <h3 class="personal_title">我的优惠券</h3>
    <div class="cont">
      <el-row type="flex" align="middle" v-for="(li, i) in list" :key="i">
        <el-col :span="18">
          <div class="j_bj bgy">
            <div class="price">
              ￥<span>{{ li.Money }}</span>
            </div>
            <div class="xian">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <p>会员专享券</p>
              <p>普通会员 下单立减</p>
            </div>
          </div>
          <div class="fw">
            <p>限范围：{{ li.CourseName }}</p>
            <p>劵编号：{{ li.CouponNumber }}</p>
            <p>
              有效期：{{ li.StartTime | dataYMD }}至{{ li.EndTime | dataYMD }}
            </p>
          </div>
        </el-col>
        <el-col :span="6">
          <!-- Status 0未使用1已使用未付款2已使用 -->
          <el-button
            @click="goTo('/course/index', li)"
            :class="li.Status == 0 ? 'btny' : 'btns'"
            >{{
              li.Status == 0 ? "立即使用" : li.Status == 3 ? "已过期" : "已使用"
            }}</el-button
          >
        </el-col>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { studentQueryStudentCouponList } from "@/api/personal";
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    goTo(url, obj) {
      if (obj.Status == 0) {
        this.$router.push({ path: url });
      }
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },
    async init() {
      let endTime = new Date();
      let parm = {
        pageIndex: this.page,
        pageSize: this.pageSize,
        // StartTime:this.common.dataYMD(endTime),
        // EndTime:this.common.dataYMD(endTime)
      };
      const res = await studentQueryStudentCouponList(parm);
      if (res.success === true) {
        this.list = res.response.data;
        this.list.forEach((item) => {
          // 获取当前系统时间戳
          let time = parseInt(new Date().getTime().toString("yyyy-MM-dd") / 1000);
          // 有效时间转时间戳
          let date = Math.round(new Date(item.EndTime).getTime() / 1000);
          if (time > date) {
            item.Status = 3;
          }
        });
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.coupon {
  font-size: 14px;
  .cont {
    padding: 8px 30px;
    .el-row {
      padding: 16px 0;
      border-bottom: 1px solid #f0eeee;
      .el-col {
        &:first-child {
          display: flex;
          .j_bj {
            // width: 250px;
            height: 90px;
            border-radius: 10px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            > div {
              &:nth-child(1) {
                min-width: 120px;
                text-align: center;
                font-size: 12px;
                span {
                  font-size: 24px;
                }
              }
              &:nth-child(2) {
                height: 100%;
                position: relative;
                span {
                  display: inline-block;
                  &:nth-child(1) {
                    width: 10px;
                    height: 10px;
                    background: #ffffff;
                    border-radius: 50%;
                    position: absolute;
                    top: -5px;
                    left: -4px;
                  }
                  &:nth-child(2) {
                    height: 100%;
                    border-right: 2px dashed #f4f5fa;
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                  &:nth-child(3) {
                    width: 10px;
                    height: 10px;
                    background: #ffffff;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -5px;
                    left: -4px;
                  }
                }
              }
              &:nth-child(3) {
                width: 100px;
                margin: 0 15px 0 10px;
                p {
                  line-height: 100%;
                  text-align: right;
                  &:nth-child(1) {
                    font-size: 14px;
                    font-weight: bold;
                  }
                  &:nth-child(2) {
                    font-size: 12px;
                    margin-top: 13px;
                  }
                }
              }
            }
          }
          .fw {
            margin-left: 30px;
            p {
              &:nth-child(2) {
                margin: 16px 0;
              }
            }
          }
        }
        &:last-child {
          .el-button {
            width: 110px;
            height: 40px;
            border-radius: 4px;
            font-size: 16px;
            color: #ffffff;
          }
          .btny {
            background-color: #fa9722;
            border-color: #fa9722;
          }
          .btns {
            background-color: #adadad;
            border-color: #adadad;
          }
        }
      }
      .bgy {
        background: linear-gradient(90deg, #ff6f44 0%, #ffcd48 100%);
      }
      .bgs {
        background: #adadad;
      }
    }
  }
}
</style>